import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SocialSection = () => {
  const data = useStaticQuery(graphql`
    query {
      fb: file(relativePath: { eq: "images/logos/facebook-circle.png" }) {
        childImageSharp {
          gatsbyImageData(width: 25)
        }
      }
      insta: file(relativePath: { eq: "images/logos/instagram-circle.png" }) {
        childImageSharp {
          gatsbyImageData(width: 25)
        }
      }
      linkedin: file(
        relativePath: { eq: "images/logos/linked-in-circle.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 25)
        }
      }
      twitter: file(relativePath: { eq: "images/logos/twitter-circle.png" }) {
        childImageSharp {
          gatsbyImageData(width: 25)
        }
      }
    }
  `);

  return (
    <div className='social-media-icons'>
      <a
        className='social-media-icon'
        href='https://www.facebook.com/vayyarltd'
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Vayyar Facebook'
      >
        <GatsbyImage
          image={data.fb.childImageSharp.gatsbyImageData}
          alt='Vayyar Facebook'
        />
      </a>
      <a
        className='social-media-icon'
        href='https://www.instagram.com/vayyar_inc'
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Vayyar Instagram'
      >
        <GatsbyImage
          image={data.insta.childImageSharp.gatsbyImageData}
          alt='Vayyar Instagram'
        />
      </a>
      <a
        className='social-media-icon'
        href='https://www.linkedin.com/company/vayyar'
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Vayyar Linkedin'
      >
        <GatsbyImage
          image={data.linkedin.childImageSharp.gatsbyImageData}
          alt='Vayyar Linkedin'
        />
      </a>
      <a
        className='social-media-icon'
        href='https://twitter.com/vayyarinc'
        target='_blank'
        rel='noopener noreferrer'
        aria-label='Vayyar Twitter'
      >
        <GatsbyImage
          image={data.twitter.childImageSharp.gatsbyImageData}
          alt='Vayyar Twitter'
        />
      </a>
    </div>
  );
};

export default SocialSection;
