import React, { Component } from 'react';
import axios from 'axios';
const defaultState = {
  currency: 'USD',
  currencies: [],
  prices: {},
  utmParams: '',
  setData: () => {},
  getPrices: () => {},
  setState: () => {},
};

const WalabotContext = React.createContext(defaultState);

class WalabotProvider extends Component {
  state = {
    currency: 'USD',
    currencies: [],
    prices: {},
    utmParams: '',
  };

  setData = (x, value) => {
    this.setState((prevState) => {
      return { ...prevState, [x]: value };
    });
  };

  getPrices = async () => {
    axios
      .get(process.env.GATSBY_PRICE_SERVER, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        this.setState((prevState) => {
          return { ...prevState, prices: response.data };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.getPrices();
  }

  render() {
    const { children } = this.props;
    const { currency, currencies, prices } = this.state;
    return (
      <WalabotContext.Provider
        value={{
          currency,
          currencies,
          prices,
          setData: this.setData,
          setState: this.setState,
          getPrices: this.getPrices,
        }}
      >
        {children}
      </WalabotContext.Provider>
    );
  }
}

export default WalabotContext;
export { WalabotContext, WalabotProvider };
