import React, {
  forwardRef,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import useWindowWidth from '../../hooks/useWindowWidth';
import TopBar from '../Header/info-bar';
import ToggleIcon from '../Header/icons/mobile-toggle.jsx';
import Logo from '../Header/logo';
import RightItems from '../Header/right-column';
import IconDIY from '../Header/icons/iconDIY';
import IconDIYBundle from '../Header/icons/iconDIYBundle';
import IconDIY2 from '../Header/icons/iconDIY2';
import IconDIY2Bundle from '../Header/icons/iconDIY2Bundle';
import IconDIYPlusX from '../Header/icons/iconDIYPlusX';
import IconDIYPlusXBundle from '../Header/icons/iconDIYPlusXBundle';
import IconDIYAccessories from '../Header/icons/iconDIYAccessories';
import IconCatalog from '../Header/icons/iconCatalog';

function reducer(state, action) {
  switch (action.type) {
    case 'show_menu': {
      return {
        ...state,
        mobileMenu: true,
      };
    }
    case 'hide_menu': {
      return {
        ...state,
        mobileMenu: false,
      };
    }
    case 'show_submenu': {
      return {
        ...state,
        subMenu: true,
      };
    }
    case 'hide_submenu': {
      return {
        ...state,
        subMenu: false,
      };
    }
    case 'update_height': {
      return {
        ...state,
        headerHeight: action.payload,
      };
    }
    default:
      return state;
  }
  //throw Error('Unknown action.');
}

const Header = forwardRef(({ props }, headerHeight) => {
  const data = useStaticQuery(graphql`
    query {
      main_logo: file(
        relativePath: { eq: "images/logos/walabot-logo-full.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [WEBP])
        }
      }
      en: translation(id: { eq: "english-global-pages" }) {
        ...GlobalTranslations
      }
    }
  `);

  const t = data['en'].translation.global.menu;
  const size = useWindowWidth();
  const windowWidth = size.windowWidth > 990 ? 'desktop' : 'mobile';
  const ref = useRef(null);

  const [state, dispatch] = useReducer(reducer, {
    mobileMenu: false,
    subMenu: false,
    headerHeight: 0,
  });

  const handleResize = useCallback(() => {
    dispatch({
      type: 'update_height',
      payload: headerHeight.current.clientHeight,
    });

    if (window.innerWidth > 990) {
      dispatch({
        type: 'hide_menu',
      });
    }
  }, [headerHeight]);

  useEffect(() => {
    dispatch({
      type: 'update_height',
      payload: headerHeight.current.clientHeight,
    });
    window.addEventListener('resize', handleResize, true);
    document.addEventListener('click', handleClickOutside, true);
  }, [handleResize, headerHeight]);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      dispatch({
        type: 'hide_submenu',
      });
    }
  };

  const displaySupport =
    windowWidth === 'mobile' && state.mobileMenu ? true : false;

  const setMobileMenu = state.mobileMenu ? 'hide_menu' : 'show_menu';
  const setSubMenu = state.subMenu ? 'hide_submenu' : 'show_submenu';

  const showMobileMenu = state.mobileMenu
    ? `header-menu mobile-nav--open`
    : 'header-menu';

  const subMenu = state.subMenu
    ? `nav-item--submenu is-open`
    : 'nav-item--submenu';

  return (
    <div className='header-section' id='walabot-header' ref={headerHeight}>
      <TopBar />
      <div className='header-wrapper'>
        <button
          type='button'
          aria-label='Toggle menu'
          className='mobile-nav--toggle large-up--hide'
          onClick={() => {
            dispatch({ type: setMobileMenu });
          }}
          onKeyDown={() => {}}
        >
          <ToggleIcon mobileMenuShowing={state.mobileMenu} />
        </button>
        <Logo
          displaySupport={displaySupport}
          logo={data.main_logo.childImageSharp.gatsbyImageData}
        />

        <nav
          className={showMobileMenu}
          id='WalabotNav'
          role='navigation'
          style={{
            height: state.mobileMenu
              ? `calc(100vh - ${state.headerHeight}px)`
              : ' ',
          }}
        >
          <ul className='main-nav'>
            <li className='nav-item'>
              <a
                href='https://walabot.com/'
                className='nav-item__link hover-purple'
              >
                <span className='nav-item__label'>Home</span>
              </a>
            </li>
            <li className='nav-item nav-item--parent'>
              <button
                type='button'
                className='submenu-toggle nav-item__link'
                onClick={() => {
                  dispatch({ type: setSubMenu });
                }}
                onKeyDown={() => {
                  dispatch({ type: setSubMenu });
                }}
              >
                <span className='nav-item__label'>Products</span>
                <div className='nav-mobile__icon'>
                  <svg
                    focusable='false'
                    role='presentation'
                    className='icon icon-chevron-down'
                    viewBox='0 0 9 9'
                  >
                    <path
                      d='M8.542 2.558a.625.625 0 0 1 0 .884l-3.6 3.6a.626.626 0 0 1-.884 0l-3.6-3.6a.625.625 0 1 1 .884-.884L4.5 5.716l3.158-3.158a.625.625 0 0 1 .884 0z'
                      fill='currentColor'
                    />
                  </svg>
                </div>
              </button>
              <div className={subMenu} ref={ref}>
                <ul className='submenu'>
                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/products/walabot-diy-2-deluxe-bundle'
                      className='submenu-item__link hover-purple'
                    >
                      <IconDIY2Bundle />
                      <span className='nav-item__label'>DIY 2 Bundle</span>
                    </a>
                  </li>
                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/products/walabot-diy-2'
                      className='submenu-item__link hover-purple'
                    >
                      <IconDIY2 />
                      <span className='nav-item__label'>DIY 2</span>
                    </a>
                  </li>

                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/products/walabot-diy-plus-x-deluxe-bundle'
                      className='submenu-item__link hover-purple'
                    >
                      <IconDIYPlusXBundle />
                      <span className='nav-item__label'>DIY Plus X Bundle</span>
                    </a>
                  </li>

                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/products/walabot-diy-plus-x'
                      className='submenu-item__link hover-purple'
                    >
                      <IconDIYPlusX />
                      <span className='nav-item__label'>DIY Plus X</span>
                    </a>
                  </li>

                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/products/walabot-bundle'
                      className='submenu-item__link hover-purple'
                    >
                      <IconDIYBundle />
                      <span className='nav-item__label'>DIY Bundle</span>
                    </a>
                  </li>

                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/products/walabot-diy-1'
                      className='submenu-item__link hover-purple'
                    >
                      <IconDIY />
                      <span className='nav-item__label'>DIY</span>
                    </a>
                  </li>

                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/collections/accessories'
                      className='submenu-item__link hover-purple'
                    >
                      {' '}
                      <IconDIYAccessories />
                      <span className='nav-item__label'>Accessories</span>
                    </a>
                  </li>
                  <li className='submenu-item'>
                    <a
                      href='https://walabot.com/collections/all'
                      className='submenu-item__link hover-purple'
                    >
                      <IconCatalog />
                      <span className='nav-item__label'>Catalog</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className='nav-item'>
              <a href='https://walabot.com/#detects' className='nav-item__link'>
                <span className='nav-item__label'>{t.detects}</span>
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='https://walabot.com/pages/whats-the-drill'
                className='nav-item__link'
              >
                <span className='nav-item__label'>{t.how_to}</span>
              </a>
            </li>
            <li className='nav-item'>
              <Link
                to={`/makers`}
                className='nav-item__link'
                onClick={() => {
                  dispatch({ type: 'hide_menu' });
                }}
                onKeyDown={() => {
                  dispatch({ type: 'hide_menu' });
                }}
              >
                <span className='nav-item__label'>Makers</span>
              </Link>
            </li>
            <li className='nav-item'>
              <a href='https://walabot.com/#reviews' className='nav-item__link'>
                <span className='nav-item__label'>{t.reviews}</span>
              </a>
            </li>
          </ul>
        </nav>
        <RightItems
          displaySupport={displaySupport}
          style={{ flex: displaySupport ? '1 1' : '' }}
        />
      </div>
    </div>
  );
});

export default Header;
