import React from 'react';

export default function SupportIcon() {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3414 1.43366C7.12405 1.43366 3.66503 4.68052 3.3277 8.81092C3.41239 8.80494 3.49792 8.80189 3.58413 8.80189H5.34008H6.05692V9.51873V18.2318V18.9486H5.34008H3.58413C1.60467 18.9486 0 17.344 0 15.3645V12.386C0 11.0275 0.755852 9.84549 1.86997 9.23761C1.9956 4.11446 6.18802 0 11.3414 0C15.4618 0 18.9659 2.62981 20.2708 6.30025C20.4034 6.67328 20.2084 7.08318 19.8354 7.21578C19.4624 7.34839 19.0526 7.15348 18.9199 6.78047C17.812 3.6636 14.8362 1.43366 11.3414 1.43366ZM3.58413 10.2355C2.39646 10.2355 1.43366 11.1984 1.43366 12.386V15.3645C1.43366 16.5521 2.39646 17.5149 3.58413 17.5149H4.62326V10.2355H3.58413ZM17.2539 8.80189H16.537V9.51873V18.0418C16.5316 19.2248 15.5709 20.1822 14.3866 20.1822H12.916C12.6464 19.6076 12.0626 19.2097 11.3859 19.2097C10.4529 19.2097 9.69661 19.966 9.69661 20.8989C9.69661 21.832 10.4529 22.5882 11.3859 22.5882C12.0625 22.5882 12.6463 22.1904 12.9159 21.616H14.3866C16.0491 21.616 17.4473 20.4839 17.8523 18.9486H19.0098C20.9893 18.9486 22.5939 17.344 22.5939 15.3645V12.386C22.5939 10.4066 20.9893 8.80189 19.0098 8.80189H17.2539ZM17.9706 10.2355V17.5149H19.0098C20.1974 17.5149 21.1602 16.5521 21.1602 15.3645V12.386C21.1602 11.1984 20.1974 10.2355 19.0098 10.2355H17.9706Z'
        fill='currentColor'
      />
    </svg>
  );
}
