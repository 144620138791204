import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Logo = ({ displaySupport }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div
      className='header-column header-column-logo'
      style={
        displaySupport && isClient
          ? { width: 0, overflow: 'hidden', padding: 0 }
          : { width: 'auto', overflow: 'initial' }
      }
    >
      <a href='https://walabot.com/'>
        <LogoImage />
      </a>
    </div>
  );
};
export default Logo;

// OK
export function LogoImage() {
  // Local variables are fine
  const width = 300;
  return (
    <StaticImage
      src='../../images/logos/walabot-logo-full.png'
      // width={width}
      loading='eager'
      alt='Walabot Logo'
      className='site-header__logo-image'
    />
  );
}
