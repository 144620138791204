import React from "react"

const IconDIY = () => {
  return (
    <svg
      width="104"
      height="84"
      viewBox="0 0 104 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 22.75H84C89.1086 22.75 93.25 26.8914 93.25 32V68C93.25 73.1086 89.1086 77.25 84 77.25H14C8.89137 77.25 4.75 73.1086 4.75 68V32C4.75 26.8914 8.89137 22.75 14 22.75Z"
        fill="url(#paint0_radial_1202_2800)"
      />
      <path
        d="M14 22.75H84C89.1086 22.75 93.25 26.8914 93.25 32V68C93.25 73.1086 89.1086 77.25 84 77.25H14C8.89137 77.25 4.75 73.1086 4.75 68V32C4.75 26.8914 8.89137 22.75 14 22.75Z"
        fill="url(#paint1_linear_1202_2800)"
        fillOpacity="0.3"
      />
      <path
        d="M14 22.75H84C89.1086 22.75 93.25 26.8914 93.25 32V68C93.25 73.1086 89.1086 77.25 84 77.25H14C8.89137 77.25 4.75 73.1086 4.75 68V32C4.75 26.8914 8.89137 22.75 14 22.75Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.2725 48.7236C50.5351 48.7236 51.5587 47.7099 51.5587 46.4593C51.5587 46.4014 51.5565 46.3441 51.5522 46.2874C53.0139 47.0874 54.0037 48.6296 54.0037 50.4007C54.0037 52.9945 51.8808 55.0972 49.2619 55.0972C46.6431 55.0972 44.5201 52.9945 44.5201 50.4007C44.5201 48.6207 45.5199 47.072 46.9937 46.2754C46.9887 46.336 46.9862 46.3974 46.9862 46.4593C46.9862 47.7099 48.0098 48.7236 49.2725 48.7236Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7505 40.1552C43.8946 40.1552 40.2313 43.4816 39.1478 48.0865C39.0115 48.6661 38.4265 49.0265 37.8413 48.8914C37.2561 48.7564 36.8923 48.177 37.0286 47.5974C38.3086 42.1571 42.7445 38 48.7505 38C54.6371 38 59.9452 41.8281 60.9832 47.6547C61.0876 48.2408 60.6926 48.7997 60.1008 48.9031C59.5091 49.0066 58.9448 48.6153 58.8404 48.0292C58.0099 43.3673 53.7258 40.1552 48.7505 40.1552ZM60.1391 51.9606C60.7268 52.0849 61.1013 52.6575 60.9758 53.2395C59.6379 59.441 54.6641 63 48.7505 63C42.7455 63 37.8958 58.2673 37.016 53.1969C36.9142 52.6104 37.3117 52.0532 37.9039 51.9524C38.4961 51.8515 39.0587 52.2453 39.1605 52.8318C39.8763 56.9568 43.8935 60.8448 48.7505 60.8448C53.6988 60.8448 57.737 57.9383 58.8478 52.7892C58.9734 52.2072 59.5515 51.8362 60.1391 51.9606Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        d="M6 17L12 21.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M19 22H84.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="1 8"
      />
      <path
        d="M58 4.75H96C96.6904 4.75 97.25 5.30964 97.25 6V76C97.25 76.6904 96.6904 77.25 96 77.25H58C57.3096 77.25 56.75 76.6904 56.75 76V6C56.75 5.30964 57.3096 4.75 58 4.75Z"
        fill="url(#paint2_radial_1202_2800)"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M77 55C84.732 55 91 48.732 91 41C91 33.268 84.732 27 77 27C69.268 27 63 33.268 63 41C63 48.732 69.268 55 77 55Z"
        fill="url(#paint3_radial_1202_2800)"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7816 39.2067C77.8999 39.2067 78.8064 38.3002 78.8064 37.182C78.8064 37.1159 78.8772 37.0711 78.9339 37.1052C80.1549 37.8397 80.9717 39.1776 80.9717 40.7063C80.9717 43.0256 79.0916 44.9057 76.7723 44.9057C74.453 44.9057 72.5729 43.0256 72.5729 40.7063C72.5729 39.1735 73.3941 37.8325 74.6206 37.0993C74.6813 37.063 74.7569 37.1113 74.7569 37.182C74.7569 38.3002 75.6634 39.2067 76.7816 39.2067Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.6198 31.8138C72.3193 31.8138 69.0751 34.7881 68.1155 38.9057C67.9948 39.424 67.4767 39.7462 66.9585 39.6255C66.4402 39.5047 66.118 38.9867 66.2387 38.4684C67.3723 33.6038 71.3008 29.8867 76.6198 29.8867C81.8332 29.8867 86.5341 33.3097 87.4534 38.5196C87.5459 39.0437 87.196 39.5435 86.672 39.636C86.1479 39.7284 85.6481 39.3785 85.5556 38.8545C84.8201 34.686 81.0261 31.8138 76.6198 31.8138ZM86.7059 42.3698C87.2263 42.481 87.558 42.993 87.4468 43.5134C86.2619 49.0585 81.8571 52.2409 76.6198 52.2409C71.3017 52.2409 67.0068 48.0091 66.2275 43.4753C66.1374 42.9508 66.4895 42.4526 67.0139 42.3625C67.5384 42.2723 68.0366 42.6244 68.1268 43.1489C68.7607 46.8373 72.3184 50.3138 76.6198 50.3138C81.0022 50.3138 84.5785 47.7149 85.5623 43.1107C85.6735 42.5903 86.1855 42.2586 86.7059 42.3698Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1963 21.0501C73.5907 21.0501 70.2231 22.0562 67.3554 23.8024C66.9009 24.0791 66.3081 23.935 66.0313 23.4805C65.7546 23.026 65.8987 22.4332 66.3532 22.1564C69.5142 20.2316 73.2273 19.123 77.1963 19.123C81.1653 19.123 84.8784 20.2316 88.0395 22.1564C88.494 22.4332 88.6381 23.026 88.3613 23.4805C88.0845 23.935 87.4917 24.0791 87.0372 23.8024C84.1695 22.0562 80.8019 21.0501 77.1963 21.0501Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.1963 16.554C72.5102 16.554 68.1479 17.9278 64.4867 20.2942C64.0398 20.5831 63.4433 20.4549 63.1544 20.008C62.8656 19.5611 62.9937 18.9646 63.4406 18.6758C67.4042 16.1139 72.128 14.627 77.1963 14.627C82.2645 14.627 86.9884 16.1139 90.952 18.6758C91.3989 18.9646 91.527 19.5611 91.2382 20.008C90.9493 20.4549 90.3528 20.5831 89.9059 20.2942C86.2447 17.9278 81.8824 16.554 77.1963 16.554Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97 49.0006C92.917 56.0707 85.4878 60.7992 77.0002 60.7992C68.5123 60.7992 61.083 56.0704 57 49V75.537C57 76.0893 57.4477 76.537 58 76.537H96C96.5523 76.537 97 76.0893 97 75.537V49.0006Z"
        fill="url(#paint4_radial_1202_2800)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1202_2800"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.99998 87.0811) rotate(-41.5758) scale(120.308 182.377)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.179218" stopColor="#14021D" />
          <stop offset="0.701069" stopColor="#292A41" />
          <stop offset="0.817754" stopColor="#3A3B4F" />
          <stop offset="0.895688" stopColor="#2B2B37" />
          <stop offset="1" stopColor="#212224" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1202_2800"
          x1="56"
          y1="47.5"
          x2="5.5"
          y2="47.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0406854" stopColor="#111111" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1202_2800"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(56 90) rotate(-68.2923) scale(113.553 119.157)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.179218" stopColor="#14021D" />
          <stop offset="0.701069" stopColor="#292A41" />
          <stop offset="0.817754" stopColor="#3A3B4F" />
          <stop offset="0.895688" stopColor="#2B2B37" />
          <stop offset="1" stopColor="#212224" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1202_2800"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(64.5 59) rotate(-52.125) scale(45.607 27.4599)"
        >
          <stop offset="0.0541388" stopOpacity="0" />
          <stop offset="0.640625" stopColor="#333B4A" stopOpacity="0.813049" />
          <stop offset="0.775481" stopColor="#B6BCC7" />
          <stop offset="1" stopColor="#353B48" stopOpacity="0.75" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_1202_2800"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(77 61.1203) rotate(90) scale(15.248 53.837)"
        >
          <stop offset="0.308032" stopColor="#8916ED" />
          <stop offset="1" stopColor="#7100D3" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default IconDIY
