import React from 'react';

const CartButton = () => {
  return (
    <a
      href='https://walabot.com/cart'
      className='site-header__icon site-header__cart'
      aria-label='Go to cart'
    >
      <svg
        width='25'
        height='25'
        viewBox='0 0 25 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.03614 19.7715C3.68938 19.7715 2.59387 20.8672 2.59387 22.2138C2.59387 23.5605 3.68938 24.656 5.03614 24.656C6.38272 24.656 7.47841 23.5605 7.47841 22.2138C7.47841 20.8672 6.38272 19.7715 5.03614 19.7715ZM5.03614 23.1464C4.5218 23.1464 4.10347 22.7281 4.10347 22.2139C4.10347 21.6996 4.5218 21.2813 5.03614 21.2813C5.55048 21.2813 5.96882 21.6996 5.96882 22.2139C5.96882 22.7281 5.55048 23.1464 5.03614 23.1464Z'
          fill='black'
        />
        <path
          d='M3.09442 12.709L1.82849 4.69141H21.6614L19.9735 13.9749H4.78233C3.76958 13.9749 3.23508 13.1309 3.09442 12.709Z'
          fill='#8916ed'
        />
        <path
          d='M18.0455 19.7715C16.6989 19.7715 15.6034 20.8672 15.6034 22.2138C15.6034 23.5605 16.6989 24.656 18.0455 24.656C19.3922 24.656 20.4879 23.5605 20.4879 22.2138C20.4879 20.8672 19.3922 19.7715 18.0455 19.7715ZM18.0455 23.1464C17.5313 23.1464 17.113 22.7281 17.113 22.2139C17.113 21.6996 17.5313 21.2813 18.0455 21.2813C18.5598 21.2813 18.9782 21.6996 18.9782 22.2139C18.9782 22.7281 18.5598 23.1464 18.0455 23.1464Z'
          fill='black'
        />
        <path
          d='M21.5988 0.65625L21.1038 3.67544H0.900879L2.3468 12.5259C2.55043 13.7672 3.69523 14.7396 4.95319 14.7396H19.2902L18.9603 16.7524H4.10332C3.68646 16.7524 3.34852 17.0904 3.34852 17.5072C3.34852 17.9241 3.68645 18.262 4.10332 18.262H20.2426L22.881 2.16585H24.6996V0.65625H21.5988ZM19.5378 13.23H4.95319C4.44125 13.23 3.91935 12.7867 3.83664 12.282L2.67708 5.18504H20.8563L19.5378 13.23Z'
          fill='black'
        />
      </svg>
    </a>
  );
};
export default CartButton;
