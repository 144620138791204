import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WalabotContext from '../../../WalabotContext';
import Header from '../layout/header';
import Footer from '../global/footer';
import 'normalize.css';
import '../../styles/stylesheet.scss';

const Layout = (props) => {
  const locale = 'en';
  const [navigationHeight, setNavigationHeight] = useState(undefined);
  const headerHeight = useRef();

  const pageUrl = props.path.split('/').filter((n) => n);
  const page = pageUrl[pageUrl.length - 1];

  useEffect(() => {
    setNavigationHeight(headerHeight.current.offsetHeight);
    document.body.className = page;
  }, [page]);

  return (
    <div className='page-container'>
      <Header props={props} ref={headerHeight} />
      <WalabotContext.Consumer>
        {() => (
          <>
            <main
              style={{
                padding: `${navigationHeight}px 0px 0px`,
              }}
            >
              {props.children}
            </main>
          </>
        )}
      </WalabotContext.Consumer>
      <Footer props={props} locale={locale} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
