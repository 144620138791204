import React, { useEffect, useState } from 'react';
import CartButton from '../Header/icons/cart';
import SupportIcon from '../Header/icons/support';
import CurrencySelector from '../global/Menu/currencySelector';

const RightItems = ({ displaySupport }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const columnRight = displaySupport
    ? 'header-column header-column__right visible'
    : 'header-column header-column__right';

  const secondaryItems = displaySupport
    ? 'secondary-items visible'
    : 'secondary-items';

  return (
    <div
      className={columnRight}
      style={displaySupport && isClient ? { flex: '1 1 0%' } : {}}
    >
      <div
        className={secondaryItems}
        style={displaySupport && isClient ? { left: 0 } : {}}
      >
        <a
          style={{ padding: '1rem' }}
          href='https://walabot.com/collections/all'
        >
          Catalog
        </a>
        <a
          href='https://support.walabot.com/'
          className='info-support large-up--hide'
        >
          <SupportIcon />
          Support
        </a>
        <CurrencySelector />
      </div>
      <CartButton />
    </div>
  );
};
export default RightItems;
