import React, { useState, useEffect, useRef, useContext } from 'react';
import WalabotContext from '../../../../WalabotContext';

const CurrencySelector = () => {
  const [menuShowing, setMenuShowing] = useState(false);
  const context = useContext(WalabotContext);
  const { currencies, prices, currency, setData } = context;

  const currencyList = (state) => {
    if (currencies.length === 0) {
      for (const product in prices) {
        if (prices[product].Global) {
          prices[product].Global.map((price) => {
            return currencies.push(price.price.currency_code);
          });
          break;
        }
      }
    }

    const options = currencies.map((option) => {
      if (option !== currency) {
        return (
          <li className='currency-option' key={option}>
            <button
              className='currency-option__link'
              onClick={(e) => {
                setData('currency', e.currentTarget.value);
                setMenuShowing(false);
              }}
              value={option}
            >
              {option}
            </button>
          </li>
        );
      } else {
        return false;
      }
    });

    return <ul className='currency-list'>{options}</ul>;
  };
  const showMenu = menuShowing
    ? `currency-list-wrapper open`
    : 'currency-list-wrapper';

  const styleToggle = menuShowing
    ? 'currency-toggle active'
    : 'currency-toggle';

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, []);

  const ref = useRef(null);

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setMenuShowing(false);
    }
  };
  return (
    <WalabotContext.Consumer>
      {(state) => (
        <div className='currency' ref={ref}>
          <button
            className={styleToggle}
            onClick={() => setMenuShowing(menuShowing ? false : true)}
            onKeyDown={() => setMenuShowing(menuShowing ? false : true)}
          >
            {state.currency}
            <div className='icon-arrow'>
              <svg
                aria-hidden='true'
                focusable='false'
                role='presentation'
                className='icon icon-chevron-down'
                viewBox='0 0 9 9'
              >
                <path
                  d='M8.542 2.558a.625.625 0 0 1 0 .884l-3.6 3.6a.626.626 0 0 1-.884 0l-3.6-3.6a.625.625 0 1 1 .884-.884L4.5 5.716l3.158-3.158a.625.625 0 0 1 .884 0z'
                  fill='currentColor'
                ></path>
              </svg>
            </div>
          </button>
          <div className={showMenu}>{currencyList(state)}</div>
        </div>
      )}
    </WalabotContext.Consumer>
  );
};

export default CurrencySelector;
