import React from "react"

const IconDIYPlusXBundle = () => {
  return (
    <svg
      width="104"
      height="84"
      viewBox="0 0 104 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 22.75H85C90.1086 22.75 94.25 26.8914 94.25 32V68C94.25 73.1086 90.1086 77.25 85 77.25H15C9.89137 77.25 5.75 73.1086 5.75 68V32C5.75 26.8914 9.89137 22.75 15 22.75Z"
        fill="url(#paint0_radial_1202_2821)"
      />
      <path
        d="M15 22.75H85C90.1086 22.75 94.25 26.8914 94.25 32V68C94.25 73.1086 90.1086 77.25 85 77.25H15C9.89137 77.25 5.75 73.1086 5.75 68V32C5.75 26.8914 9.89137 22.75 15 22.75Z"
        fill="url(#paint1_linear_1202_2821)"
        fillOpacity="0.3"
      />
      <path
        d="M15 22.75H85C90.1086 22.75 94.25 26.8914 94.25 32V68C94.25 73.1086 90.1086 77.25 85 77.25H15C9.89137 77.25 5.75 73.1086 5.75 68V32C5.75 26.8914 9.89137 22.75 15 22.75Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.2725 48.7236C51.5351 48.7236 52.5587 47.7099 52.5587 46.4593C52.5587 46.4014 52.5565 46.3441 52.5522 46.2874C54.0139 47.0874 55.0037 48.6296 55.0037 50.4007C55.0037 52.9945 52.8808 55.0972 50.2619 55.0972C47.6431 55.0972 45.5201 52.9945 45.5201 50.4007C45.5201 48.6207 46.5199 47.072 47.9937 46.2754C47.9887 46.336 47.9862 46.3974 47.9862 46.4593C47.9862 47.7099 49.0098 48.7236 50.2725 48.7236Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7505 40.1552C44.8946 40.1552 41.2313 43.4816 40.1478 48.0865C40.0115 48.6661 39.4265 49.0265 38.8413 48.8914C38.2561 48.7564 37.8923 48.177 38.0286 47.5974C39.3086 42.1571 43.7445 38 49.7505 38C55.6371 38 60.9452 41.8281 61.9832 47.6547C62.0876 48.2408 61.6926 48.7997 61.1008 48.9031C60.5091 49.0066 59.9448 48.6153 59.8404 48.0292C59.0099 43.3673 54.7258 40.1552 49.7505 40.1552ZM61.1391 51.9606C61.7268 52.0849 62.1013 52.6575 61.9758 53.2395C60.6379 59.441 55.6641 63 49.7505 63C43.7455 63 38.8958 58.2673 38.016 53.1969C37.9142 52.6104 38.3117 52.0532 38.9039 51.9524C39.4961 51.8515 40.0587 52.2453 40.1605 52.8318C40.8763 56.9568 44.8935 60.8448 49.7505 60.8448C54.6988 60.8448 58.737 57.9383 59.8478 52.7892C59.9734 52.2072 60.5515 51.8362 61.1391 51.9606Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        d="M7 17L13 21.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M20 22H85.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="1 8"
      />
      <path
        d="M61 4.5H97C97.8284 4.5 98.5 5.17157 98.5 6V76C98.5 76.8284 97.8284 77.5 97 77.5H61C60.1716 77.5 59.5 76.8284 59.5 76V6C59.5 5.17157 60.1716 4.5 61 4.5Z"
        fill="url(#paint2_radial_1202_2821)"
        stroke="#630BAF"
      />
      <path
        d="M79 56C87.8366 56 95 48.8366 95 40C95 31.1634 87.8366 24 79 24C70.1634 24 63 31.1634 63 40C63 48.8366 70.1634 56 79 56Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.8125 24.9433C70.4968 24.9433 63.7556 31.6845 63.7556 40.0002C63.7556 48.316 70.4968 55.0572 78.8125 55.0572C87.1282 55.0572 93.8694 48.316 93.8694 40.0002C93.8694 31.6845 87.1282 24.9433 78.8125 24.9433ZM62.5222 40.0002C62.5222 31.0034 69.8156 23.71 78.8125 23.71C87.8094 23.71 95.1028 31.0034 95.1028 40.0002C95.1028 48.9971 87.8094 56.2905 78.8125 56.2905C69.8156 56.2905 62.5222 48.9971 62.5222 40.0002Z"
        fill="url(#paint3_radial_1202_2821)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7816 38.2067C79.8999 38.2067 80.8064 37.3002 80.8064 36.182C80.8064 36.1159 80.8772 36.0711 80.9339 36.1052C82.1549 36.8397 82.9717 38.1776 82.9717 39.7063C82.9717 42.0256 81.0916 43.9057 78.7723 43.9057C76.453 43.9057 74.5729 42.0256 74.5729 39.7063C74.5729 38.1735 75.3941 36.8325 76.6206 36.0993C76.6813 36.063 76.7569 36.1113 76.7569 36.182C76.7569 37.3002 77.6634 38.2067 78.7816 38.2067Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.6198 30.8138C74.3193 30.8138 71.0751 33.7881 70.1155 37.9057C69.9948 38.424 69.4767 38.7462 68.9585 38.6255C68.4402 38.5047 68.118 37.9867 68.2387 37.4684C69.3723 32.6038 73.3008 28.8867 78.6198 28.8867C83.8332 28.8867 88.5341 32.3097 89.4534 37.5196C89.5459 38.0437 89.196 38.5435 88.672 38.636C88.1479 38.7284 87.6481 38.3785 87.5556 37.8545C86.8201 33.686 83.0261 30.8138 78.6198 30.8138ZM88.7059 41.3698C89.2263 41.481 89.558 41.993 89.4468 42.5134C88.2619 48.0585 83.8571 51.2409 78.6198 51.2409C73.3017 51.2409 69.0068 47.0091 68.2275 42.4753C68.1374 41.9508 68.4895 41.4526 69.0139 41.3625C69.5384 41.2723 70.0366 41.6244 70.1268 42.1489C70.7607 45.8373 74.3184 49.3138 78.6198 49.3138C83.0022 49.3138 86.5785 46.7149 87.5623 42.1107C87.6735 41.5903 88.1855 41.2586 88.7059 41.3698Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.8528 71.9751C93.3146 72.3271 92.6923 72.4365 92.2738 72.4365H92.2672L78.7201 72.1441L65.1731 72.4365H65.1665C64.7479 72.4365 64.1257 72.3271 63.5875 71.9751C63.016 71.6014 62.5635 70.9645 62.5635 70.0158V62.9456C62.5635 62.487 62.6252 61.827 62.9908 61.2328C63.3762 60.6065 64.0455 60.1384 65.0815 59.9943C66.8066 59.7544 74.5781 58.9443 78.4939 58.647C78.7235 58.6296 78.9506 58.6296 79.1804 58.6471C83.087 58.9445 90.6353 59.7546 92.3588 59.9943C93.3948 60.1384 94.0641 60.6065 94.4495 61.2328C94.8151 61.827 94.8768 62.487 94.8768 62.9456V70.0157C94.8768 70.9645 94.4243 71.6014 93.8528 71.9751Z"
        fill="#111111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5875 8.46138C64.1257 8.10941 64.7479 8 65.1664 8H65.1731L78.7201 8.29241L92.2672 8H92.2738C92.6923 8 93.3145 8.10941 93.8528 8.46138C94.4242 8.83511 94.8768 9.47202 94.8768 10.4208V17.4909C94.8768 17.9496 94.815 18.6095 94.4494 19.2037C94.0641 19.8301 93.3948 20.2981 92.3588 20.4422C90.6336 20.6822 82.8622 21.4923 78.9464 21.7895C78.7167 21.8069 78.4897 21.807 78.2599 21.7895C74.3533 21.492 66.805 20.6819 65.0815 20.4422C64.0455 20.2981 63.3761 19.8301 62.9908 19.2037C62.6252 18.6095 62.5635 17.9496 62.5635 17.4909V10.4208C62.5635 9.47202 63.016 8.83511 63.5875 8.46138Z"
        fill="#111111"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1202_2821"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(4.99998 87.0811) rotate(-41.5758) scale(120.308 182.377)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.179218" stopColor="#14021D" />
          <stop offset="0.701069" stopColor="#292A41" />
          <stop offset="0.817754" stopColor="#3A3B4F" />
          <stop offset="0.895688" stopColor="#2B2B37" />
          <stop offset="1" stopColor="#212224" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1202_2821"
          x1="57"
          y1="47.5"
          x2="6.5"
          y2="47.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0406854" stopColor="#111111" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1202_2821"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(78.7531 135.967) rotate(-89.3999) scale(150.886 108.747)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.455655" stopColor="#8916ED" />
          <stop offset="0.624168" stopColor="#8916ED" />
          <stop offset="0.752072" stopColor="#8916ED" />
          <stop offset="0.856244" stopColor="#AA48FF" />
          <stop offset="0.928024" stopColor="#8916ED" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1202_2821"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(78 26) rotate(88.4635) scale(30.3014 30.3014)"
        >
          <stop stopColor="#8B97AD" />
          <stop offset="0.588542" stopColor="#8B97AD" stopOpacity="0.411458" />
          <stop offset="0.9999" stopColor="#8B97AD" stopOpacity="0.411458" />
          <stop offset="1" stopColor="#8B97AD" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default IconDIYPlusXBundle
