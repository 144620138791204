import React from 'react';

const IconDIY = () => {
  return (
    <svg
      width='54'
      height='84'
      viewBox='0 0 54 84'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 6C5 4.89543 5.89543 4 7 4H44C45.1046 4 46 4.89543 46 6V76C46 77.1046 45.1046 78 44 78H7C5.89543 78 5 77.1046 5 76V6Z'
        fill='url(#paint0_radial_1202_2789)'
      />
      <path
        d='M7 4.75H44C44.6904 4.75 45.25 5.30964 45.25 6V76C45.25 76.6904 44.6904 77.25 44 77.25H7C6.30964 77.25 5.75 76.6904 5.75 76V6C5.75 5.30964 6.30964 4.75 7 4.75Z'
        stroke='black'
        strokeWidth='1.5'
      />
      <path
        d='M26 55C33.732 55 40 48.732 40 41C40 33.268 33.732 27 26 27C18.268 27 12 33.268 12 41C12 48.732 18.268 55 26 55Z'
        fill='url(#paint1_radial_1202_2789)'
        fillOpacity='0.5'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.7816 39.2067C26.8999 39.2067 27.8064 38.3002 27.8064 37.182C27.8064 37.1159 27.8772 37.0711 27.9339 37.1052C29.1549 37.8397 29.9717 39.1776 29.9717 40.7063C29.9717 43.0256 28.0916 44.9057 25.7723 44.9057C23.453 44.9057 21.5729 43.0256 21.5729 40.7063C21.5729 39.1735 22.3941 37.8325 23.6206 37.0993C23.6813 37.063 23.7569 37.1113 23.7569 37.182C23.7569 38.3002 24.6634 39.2067 25.7816 39.2067Z'
        fill='#8916ED'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25.6198 31.8138C21.3193 31.8138 18.0751 34.7881 17.1155 38.9057C16.9948 39.424 16.4767 39.7462 15.9585 39.6255C15.4402 39.5047 15.118 38.9867 15.2387 38.4684C16.3723 33.6038 20.3008 29.8867 25.6198 29.8867C30.8332 29.8867 35.5341 33.3097 36.4534 38.5196C36.5459 39.0437 36.196 39.5435 35.672 39.636C35.1479 39.7284 34.6481 39.3785 34.5556 38.8545C33.8201 34.686 30.0261 31.8138 25.6198 31.8138ZM35.7059 42.3698C36.2263 42.481 36.558 42.993 36.4468 43.5134C35.2619 49.0585 30.8571 52.2409 25.6198 52.2409C20.3017 52.2409 16.0068 48.0091 15.2275 43.4753C15.1374 42.9508 15.4895 42.4526 16.0139 42.3625C16.5384 42.2723 17.0366 42.6244 17.1268 43.1489C17.7607 46.8373 21.3184 50.3138 25.6198 50.3138C30.0022 50.3138 33.5785 47.7149 34.5623 43.1107C34.6735 42.5903 35.1855 42.2586 35.7059 42.3698Z'
        fill='#8916ED'
        fillOpacity='0.8'
      />
      <defs>
        <radialGradient
          id='paint0_radial_1202_2789'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(5 90) rotate(-68.7626) scale(113.187 116.696)'
        >
          <stop stopColor='#71717E' />
          <stop offset='0.0001' />
          <stop offset='0.179218' stopColor='#14021D' />
          <stop offset='0.701069' stopColor='#292A41' />
          <stop offset='0.817754' stopColor='#3A3B4F' />
          <stop offset='0.895688' stopColor='#2B2B37' />
          <stop offset='1' stopColor='#212224' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_1202_2789'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(12 58.5) rotate(-50.2739) scale(46.1573 27.7912)'
        >
          <stop offset='0.0541388' stopOpacity='0' />
          <stop offset='0.640625' stopColor='#333B4A' stopOpacity='0.813049' />
          <stop offset='0.775481' stopColor='#B6BCC7' />
          <stop offset='1' stopColor='#353B48' stopOpacity='0.75' />
        </radialGradient>
      </defs>
    </svg>
  );
};
export default IconDIY;
