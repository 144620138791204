import React from 'react';
import SupportIcon from './icons/support';
const TopBar = () => {
  return (
    <div className='header-info'>
      <div className='header-info--wrapper'>
        <div className='header-info__left'>
          <div className='open-hours'>
            <p>Open 9am - 5pm EST</p>
            <a href='tel:18449252268'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                version='1.1'
                x='0px'
                y='0px'
                width='14px'
                height='14px'
                viewBox='0 0 396.454 396.454'
                fill='currentColor'
                stroke='currentColor'
                strokeWidth='0'
              >
                <g />
                <path d='M332.821,367.991c-11.233,12.985-92.111,94.404-213.951-95.18C-9.557,72.934,67.444,18.2,100.847,4.695l0.005-0.043 c0.53-0.214,1.059-0.378,1.597-0.583c0.936-0.359,1.814-0.673,2.67-0.971c0.367-0.118,0.752-0.263,1.123-0.381 c4.699-1.545,7.692-2.049,7.692-2.049s-0.021,0.088-0.037,0.23c22.136-3.875,42.897,4.896,46.869,24.295l6.982,44.047 c4.748,23.136-13.581,50.933-40.933,62.112l-2.213,0.897c14.472,50.233,43.696,108.053,100.981,160.677l4.213-3.666 c22.285-19.409,55.352-23.245,73.878-8.611l32.559,28.295C354.3,323.231,353.613,348.688,332.821,367.991z' />
              </svg>{' '}
              1-844-WALABOT
            </a>
          </div>
        </div>
        <div className='header-info__right'>
          <a href='https://support.walabot.com/' className='info-support'>
            <SupportIcon />
            Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
