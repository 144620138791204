import React from "react"

const IconDIYPlusX = () => {
  return (
    <svg
      width="50"
      height="84"
      viewBox="0 0 50 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7C5 5.89543 5.89543 5 7 5H43C44.1046 5 45 5.89543 45 7V77C45 78.1046 44.1046 79 43 79H7C5.89543 79 5 78.1046 5 77V7Z"
        fill="url(#paint0_radial_1202_2743)"
      />
      <path
        d="M5.5 7C5.5 6.17157 6.17157 5.5 7 5.5H43C43.8284 5.5 44.5 6.17157 44.5 7V77C44.5 77.8284 43.8284 78.5 43 78.5H7C6.17157 78.5 5.5 77.8284 5.5 77V7Z"
        stroke="#630BAF"
      />
      <path
        d="M25 57C33.8366 57 41 49.8366 41 41C41 32.1634 33.8366 25 25 25C16.1634 25 9 32.1634 9 41C9 49.8366 16.1634 57 25 57Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.8125 25.9433C16.4968 25.9433 9.75555 32.6845 9.75555 41.0002C9.75555 49.316 16.4968 56.0572 24.8125 56.0572C33.1282 56.0572 39.8694 49.316 39.8694 41.0002C39.8694 32.6845 33.1282 25.9433 24.8125 25.9433ZM8.52222 41.0002C8.52222 32.0034 15.8156 24.71 24.8125 24.71C33.8094 24.71 41.1028 32.0034 41.1028 41.0002C41.1028 49.9971 33.8094 57.2905 24.8125 57.2905C15.8156 57.2905 8.52222 49.9971 8.52222 41.0002Z"
        fill="url(#paint1_radial_1202_2743)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7816 39.2067C25.8999 39.2067 26.8064 38.3002 26.8064 37.182C26.8064 37.1159 26.8772 37.0711 26.9339 37.1052C28.1549 37.8397 28.9717 39.1776 28.9717 40.7063C28.9717 43.0256 27.0916 44.9057 24.7723 44.9057C22.453 44.9057 20.5729 43.0256 20.5729 40.7063C20.5729 39.1735 21.3941 37.8325 22.6206 37.0993C22.6813 37.063 22.7569 37.1113 22.7569 37.182C22.7569 38.3002 23.6634 39.2067 24.7816 39.2067Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6198 31.8138C20.3193 31.8138 17.0751 34.7881 16.1155 38.9057C15.9948 39.424 15.4767 39.7462 14.9585 39.6255C14.4402 39.5047 14.118 38.9867 14.2387 38.4684C15.3723 33.6038 19.3008 29.8867 24.6198 29.8867C29.8332 29.8867 34.5341 33.3097 35.4534 38.5196C35.5459 39.0437 35.196 39.5435 34.6719 39.636C34.1479 39.7284 33.6481 39.3785 33.5556 38.8545C32.8201 34.686 29.0261 31.8138 24.6198 31.8138ZM34.7059 42.3698C35.2263 42.481 35.558 42.993 35.4468 43.5134C34.2619 49.0585 29.8571 52.2409 24.6198 52.2409C19.3017 52.2409 15.0068 48.0091 14.2275 43.4753C14.1374 42.9508 14.4895 42.4526 15.0139 42.3625C15.5384 42.2723 16.0366 42.6244 16.1268 43.1489C16.7607 46.8373 20.3184 50.3138 24.6198 50.3138C29.0022 50.3138 32.5785 47.7149 33.5623 43.1107C33.6735 42.5903 34.1855 42.2586 34.7059 42.3698Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.8526 72.9751C39.3144 73.3271 38.6921 73.4365 38.2736 73.4365H38.267L24.7199 73.1441L11.1729 73.4365H11.1663C10.7477 73.4365 10.1255 73.3271 9.58728 72.9751C9.0158 72.6014 8.56326 71.9645 8.56326 71.0158V63.9456C8.56326 63.487 8.62501 62.827 8.9906 62.2328C9.37594 61.6065 10.0453 61.1384 11.0813 60.9943C12.8064 60.7544 20.5779 59.9443 24.4937 59.647C24.7233 59.6296 24.9504 59.6296 25.1802 59.6471C29.0868 59.9445 36.6351 60.7546 38.3586 60.9943C39.3946 61.1384 40.0639 61.6065 40.4493 62.2328C40.8149 62.827 40.8766 63.487 40.8766 63.9456V71.0157C40.8766 71.9645 40.4241 72.6014 39.8526 72.9751Z"
        fill="#111111"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58726 9.46138C10.1255 9.10941 10.7477 9 11.1662 9H11.1729L24.7199 9.29241L38.267 9H38.2736C38.6921 9 39.3143 9.10941 39.8526 9.46138C40.424 9.83511 40.8766 10.472 40.8766 11.4208V18.4909C40.8766 18.9496 40.8148 19.6095 40.4492 20.2037C40.0639 20.8301 39.3946 21.2981 38.3586 21.4422C36.6334 21.6821 28.862 22.4923 24.9462 22.7895C24.7165 22.8069 24.4895 22.807 24.2597 22.7895C20.3531 22.492 12.8048 21.6819 11.0813 21.4422C10.0453 21.2981 9.37592 20.8301 8.99058 20.2037C8.62499 19.6095 8.56324 18.9496 8.56323 18.4909V11.4208C8.56323 10.472 9.01578 9.83511 9.58726 9.46138Z"
        fill="#111111"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1202_2743"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.7531 136.967) rotate(-89.3999) scale(150.886 108.747)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.455655" stopColor="#8916ED" />
          <stop offset="0.624168" stopColor="#8916ED" />
          <stop offset="0.752072" stopColor="#8916ED" />
          <stop offset="0.856244" stopColor="#AA48FF" />
          <stop offset="0.928024" stopColor="#8916ED" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1202_2743"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24 27) rotate(88.4635) scale(30.3014 30.3014)"
        >
          <stop stopColor="#8B97AD" />
          <stop offset="0.588542" stopColor="#8B97AD" stopOpacity="0.411458" />
          <stop offset="0.9999" stopColor="#8B97AD" stopOpacity="0.411458" />
          <stop offset="1" stopColor="#8B97AD" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default IconDIYPlusX
