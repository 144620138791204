import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const LogoSection = () => {
  const data = useStaticQuery(graphql`
    query {
      footerLogo: file(
        relativePath: { eq: "images/logos/walabot-logo-full-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  return (
    <div className='section logo diy2'>
      <div className='logo-wrapper diy2'>
        <GatsbyImage
          className='footer-logo'
          image={data.footerLogo.childImageSharp.gatsbyImageData}
          alt='Walabot Logo'
        />
      </div>
      <p>
        Aero Fulfillment Services
        <br />
        6023 Union Centre Blvd
        <br />
        Fairfield, OH 45014
        <br />
        USA
      </p>
      <a href='mailto:support@walabot.com'>support@walabot.com</a>
    </div>
  );
};

export default LogoSection;
