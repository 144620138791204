import { useState, useEffect } from 'react';

const useWindowWidth = () => {
  const isBrowser = typeof window !== 'undefined';

  const [windowWidth, setWindowWidth] = useState(
    isBrowser ? window.innerWidth : 0
  );

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return {
    windowWidth,
  };
};

export default useWindowWidth;
