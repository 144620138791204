import React from "react"

const IconDIYAccessories = () => {
  return (
    <svg
      width="41"
      height="78"
      viewBox="0 0 41 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 3H10C9.44772 3 9 3.44772 9 4V25C9 25.5523 9.44772 26 10 26H31C31.5523 26 32 25.5523 32 25V4C32 3.44772 31.5523 3 31 3Z"
        fill="url(#paint0_linear_1202_2839)"
      />
      <path
        d="M31 3H10C9.44772 3 9 3.44772 9 4V25C9 25.5523 9.44772 26 10 26H31C31.5523 26 32 25.5523 32 25V4C32 3.44772 31.5523 3 31 3Z"
        fill="url(#paint1_linear_1202_2839)"
        fillOpacity="0.51"
      />
      <path
        d="M24 65H17C16.4477 65 16 65.4477 16 66V73C16 73.5523 16.4477 74 17 74H24C24.5523 74 25 73.5523 25 73V66C25 65.4477 24.5523 65 24 65Z"
        fill="url(#paint2_linear_1202_2839)"
      />
      <path
        d="M24 65H17C16.4477 65 16 65.4477 16 66V73C16 73.5523 16.4477 74 17 74H24C24.5523 74 25 73.5523 25 73V66C25 65.4477 24.5523 65 24 65Z"
        fill="url(#paint3_linear_1202_2839)"
        fillOpacity="0.2"
      />
      <path
        d="M26 57H15C14.4477 57 14 57.4477 14 58V65C14 65.5523 14.4477 66 15 66H26C26.5523 66 27 65.5523 27 65V58C27 57.4477 26.5523 57 26 57Z"
        fill="url(#paint4_linear_1202_2839)"
      />
      <path
        d="M26 57H15C14.4477 57 14 57.4477 14 58V65C14 65.5523 14.4477 66 15 66H26C26.5523 66 27 65.5523 27 65V58C27 57.4477 26.5523 57 26 57Z"
        fill="url(#paint5_linear_1202_2839)"
        fillOpacity="0.2"
      />
      <path
        d="M33 21H8C5.79086 21 4 22.7909 4 25V54C4 56.2091 5.79086 58 8 58H33C35.2091 58 37 56.2091 37 54V25C37 22.7909 35.2091 21 33 21Z"
        fill="url(#paint6_linear_1202_2839)"
      />
      <path
        d="M18.448 42.9956H14.9565C14.5527 42.9956 14.3155 42.5418 14.5459 42.2102L22.1224 31.31C22.4019 30.9079 23.033 31.1057 23.033 31.5954V37.7778H27.0219C27.4298 37.7778 27.666 38.2399 27.4272 38.5706L19.3533 49.7468C19.0693 50.14 18.448 49.9391 18.448 49.454V42.9956Z"
        fill="url(#paint7_radial_1202_2839)"
      />
      <path
        d="M17 8H14C13.4477 8 13 8.44772 13 9V12C13 12.5523 13.4477 13 14 13H17C17.5523 13 18 12.5523 18 12V9C18 8.44772 17.5523 8 17 8Z"
        fill="url(#paint8_linear_1202_2839)"
      />
      <path
        d="M27.0002 8H24.0002C23.448 8 23.0002 8.44772 23.0002 9V12C23.0002 12.5523 23.448 13 24.0002 13H27.0002C27.5525 13 28.0002 12.5523 28.0002 12V9C28.0002 8.44772 27.5525 8 27.0002 8Z"
        fill="url(#paint9_linear_1202_2839)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1202_2839"
          x1="32"
          y1="14.8108"
          x2="9"
          y2="14.8108"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="0.0885417" stopColor="#E9E8E8" />
          <stop offset="0.166667" stopColor="#D6D6D6" />
          <stop offset="0.84375" stopColor="#CDCECF" />
          <stop offset="0.924757" stopColor="#E0E0E0" />
          <stop offset="1" stopColor="#D6D6D6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1202_2839"
          x1="20.5"
          y1="3"
          x2="20.5"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.5625" stopColor="#D9DBE0" stopOpacity="0.48" />
          <stop offset="1" stopColor="#868A8F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1202_2839"
          x1="25"
          y1="69.6216"
          x2="16"
          y2="69.6216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="0.0885417" stopColor="#E9E8E8" />
          <stop offset="0.166667" stopColor="#EFEEEE" />
          <stop offset="0.84375" stopColor="#F0EBEB" />
          <stop offset="0.924757" stopColor="#EBEBEB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1202_2839"
          x1="20.5"
          y1="65"
          x2="20.5"
          y2="74"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1202_2839"
          x1="27"
          y1="61.6216"
          x2="14"
          y2="61.6216"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="0.0885417" stopColor="#E9E8E8" />
          <stop offset="0.166667" stopColor="#EFEEEE" />
          <stop offset="0.84375" stopColor="#F0EBEB" />
          <stop offset="0.924757" stopColor="#EBEBEB" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1202_2839"
          x1="20.5"
          y1="57"
          x2="20.5"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_1202_2839"
          x1="37"
          y1="40"
          x2="4"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset="0.0885417" stopColor="#E9E8E8" />
          <stop offset="0.166667" stopColor="#EFEEEE" />
          <stop offset="0.84375" stopColor="#F0EBEB" />
          <stop offset="0.924757" stopColor="#EBEBEB" />
          <stop offset="1" stopColor="#E8E8E8" />
        </linearGradient>
        <radialGradient
          id="paint7_radial_1202_2839"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21 40.5) rotate(90) scale(12.6875 8.45833)"
        >
          <stop offset="0.359375" stopColor="#811BDA" />
          <stop offset="1" stopColor="#4D0B86" />
        </radialGradient>
        <linearGradient
          id="paint8_linear_1202_2839"
          x1="18"
          y1="10.5676"
          x2="13"
          y2="10.5676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C9A9A" />
          <stop offset="0.0001" stopColor="#8A8888" />
          <stop offset="0.0885417" stopColor="#9C9B9B" />
          <stop offset="0.166667" stopColor="#AEAEAE" />
          <stop offset="0.84375" stopColor="#8E8E8E" />
          <stop offset="0.924757" stopColor="#ADA7A7" />
          <stop offset="1" stopColor="#B4B4B4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_1202_2839"
          x1="28.0002"
          y1="10.5676"
          x2="23.0002"
          y2="10.5676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9C9A9A" />
          <stop offset="0.0001" stopColor="#8A8888" />
          <stop offset="0.0885417" stopColor="#9C9B9B" />
          <stop offset="0.166667" stopColor="#AEAEAE" />
          <stop offset="0.84375" stopColor="#8E8E8E" />
          <stop offset="0.924757" stopColor="#ADA7A7" />
          <stop offset="1" stopColor="#B4B4B4" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconDIYAccessories
