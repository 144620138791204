import React from "react"

const IconDIY2 = () => {
  return (
    <svg
      width="52"
      height="84"
      viewBox="0 0 52 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 6C5 4.89543 5.89543 4 7 4H45C46.1046 4 47 4.89543 47 6V76C47 77.1046 46.1046 78 45 78H7C5.89543 78 5 77.1046 5 76V6Z"
        fill="url(#paint0_radial_1202_2773)"
      />
      <path
        d="M7 4.75H45C45.6904 4.75 46.25 5.30964 46.25 6V76C46.25 76.6904 45.6904 77.25 45 77.25H7C6.30964 77.25 5.75 76.6904 5.75 76V6C5.75 5.30964 6.30964 4.75 7 4.75Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M26 55C33.732 55 40 48.732 40 41C40 33.268 33.732 27 26 27C18.268 27 12 33.268 12 41C12 48.732 18.268 55 26 55Z"
        fill="url(#paint1_radial_1202_2773)"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.7815 39.2067C26.8997 39.2067 27.8062 38.3002 27.8062 37.182C27.8062 37.1159 27.8771 37.0711 27.9338 37.1052C29.1548 37.8397 29.9716 39.1776 29.9716 40.7063C29.9716 43.0256 28.0915 44.9057 25.7722 44.9057C23.4529 44.9057 21.5728 43.0256 21.5728 40.7063C21.5728 39.1735 22.394 37.8325 23.6204 37.0993C23.6811 37.063 23.7568 37.1113 23.7568 37.182C23.7568 38.3002 24.6633 39.2067 25.7815 39.2067Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6198 31.8138C21.3193 31.8138 18.0751 34.7881 17.1155 38.9057C16.9948 39.424 16.4767 39.7462 15.9585 39.6255C15.4402 39.5047 15.118 38.9867 15.2387 38.4684C16.3723 33.6038 20.3008 29.8867 25.6198 29.8867C30.8332 29.8867 35.5341 33.3097 36.4534 38.5196C36.5459 39.0437 36.196 39.5435 35.672 39.636C35.1479 39.7284 34.6481 39.3785 34.5556 38.8545C33.8201 34.686 30.0261 31.8138 25.6198 31.8138ZM35.7059 42.3698C36.2263 42.481 36.558 42.993 36.4468 43.5134C35.2619 49.0585 30.8571 52.2409 25.6198 52.2409C20.3017 52.2409 16.0068 48.0091 15.2275 43.4753C15.1374 42.9508 15.4895 42.4526 16.0139 42.3625C16.5384 42.2723 17.0366 42.6244 17.1268 43.1489C17.7607 46.8373 21.3184 50.3138 25.6198 50.3138C30.0022 50.3138 33.5785 47.7149 34.5623 43.1107C34.6735 42.5903 35.1855 42.2586 35.7059 42.3698Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1963 21.0501C22.5907 21.0501 19.2231 22.0562 16.3554 23.8024C15.9009 24.0791 15.3081 23.935 15.0313 23.4805C14.7546 23.026 14.8987 22.4332 15.3532 22.1564C18.5142 20.2316 22.2273 19.123 26.1963 19.123C30.1653 19.123 33.8784 20.2316 37.0395 22.1564C37.494 22.4332 37.6381 23.026 37.3613 23.4805C37.0845 23.935 36.4917 24.0791 36.0372 23.8024C33.1695 22.0562 29.8019 21.0501 26.1963 21.0501Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1963 16.554C21.5102 16.554 17.1479 17.9278 13.4867 20.2942C13.0398 20.5831 12.4433 20.4549 12.1544 20.008C11.8656 19.5611 11.9937 18.9646 12.4406 18.6758C16.4042 16.1139 21.128 14.627 26.1963 14.627C31.2645 14.627 35.9884 16.1139 39.952 18.6758C40.3989 18.9646 40.527 19.5611 40.2382 20.008C39.9493 20.4549 39.3528 20.5831 38.9059 20.2942C35.2447 17.9278 30.8824 16.554 26.1963 16.554Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46 49.0006C41.917 56.1896 34.4878 60.9976 26.0002 60.9976C17.5123 60.9976 10.083 56.1893 6 49V75.9832C6 76.5448 6.44771 77 7 77H45C45.5523 77 46 76.5448 46 75.9832V49.0006Z"
        fill="url(#paint2_radial_1202_2773)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1202_2773"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5 90) rotate(-68.2923) scale(113.553 119.157)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.179218" stopColor="#14021D" />
          <stop offset="0.701069" stopColor="#292A41" />
          <stop offset="0.817754" stopColor="#3A3B4F" />
          <stop offset="0.895688" stopColor="#2B2B37" />
          <stop offset="1" stopColor="#212224" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1202_2773"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.5 59) rotate(-52.125) scale(45.607 27.4599)"
        >
          <stop offset="0.0541388" stopOpacity="0" />
          <stop offset="0.640625" stopColor="#333B4A" stopOpacity="0.813049" />
          <stop offset="0.775481" stopColor="#B6BCC7" />
          <stop offset="1" stopColor="#353B48" stopOpacity="0.75" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1202_2773"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26 61.3241) rotate(90) scale(15.5044 53.837)"
        >
          <stop offset="0.308032" stopColor="#8916ED" />
          <stop offset="1" stopColor="#7100D3" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default IconDIY2
