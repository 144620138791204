import React, { useState } from 'react';
import { Link } from 'gatsby';

const MobileContent = ({ t }) => {
  const [ulShowing, setUlShowing] = useState('none');

  return (
    <>
      <div className='section middle mobile'>
        <button
          className={`title ${ulShowing === 'collections' ? 'active' : ''}`}
          onClick={() =>
            setUlShowing(ulShowing === 'collections' ? 'none' : 'collections')
          }
          onKeyDown={() =>
            setUlShowing(ulShowing === 'collections' ? 'none' : 'collections')
          }
        >
          Collections
        </button>
        {ulShowing === 'collections' && (
          <ul>
            <li>
              <a href='https://walabot.com/pages/devices'>Devices Collection</a>
            </li>
            <li>
              <a href='https://walabot.com/'>Bundles Collection</a>
            </li>

            <li>
              <a href='https://walabot.com/pages/walabot-diy-2'>DIY 2</a>
            </li>
            <li>
              <a href='https://walabot.com/pages/walabot-diy-plus-x'>
                DIY Plus X
              </a>
            </li>
          </ul>
        )}
      </div>
      <div className='section middle mobile'>
        <button
          className={`title ${ulShowing === 'support' ? 'active' : ''}`}
          onClick={() =>
            setUlShowing(ulShowing === 'support' ? 'none' : 'support')
          }
          onKeyDown={() =>
            setUlShowing(ulShowing === 'support' ? 'none' : 'support')
          }
        >
          {t.support}
        </button>
        {ulShowing === 'support' && (
          <ul>
            <li>
              <a
                href='https://support.walabot.com'
                target='_blank'
                rel='noopener noreferrer'
              >
                {t.contact}
              </a>
            </li>
            <li>
              <a href='https://walabot.com/pages/return'>{t.return}</a>
            </li>
          </ul>
        )}
      </div>
      <div className='section middle mobile'>
        <button
          className={`title ${ulShowing === 'company' ? 'active' : ''}`}
          onClick={() =>
            setUlShowing(ulShowing === 'company' ? 'none' : 'company')
          }
          onKeyDown={() =>
            setUlShowing(ulShowing === 'company' ? 'none' : 'company')
          }
        >
          {t.company}
        </button>
        {ulShowing === 'company' && (
          <ul>
            <li>
              <a href='https://walabot.com/pages/about-us'>{t.about}</a>
            </li>
            <li>
              <Link to={`/docs/walabot-press-kit`}>{t.press}</Link>
            </li>
          </ul>
        )}
      </div>
      <div className='section middle mobile'>
        <button
          className={`title ${ulShowing === 'more' ? 'active' : ''}`}
          onClick={() => setUlShowing(ulShowing === 'more' ? 'none' : 'more')}
          onKeyDown={() => setUlShowing(ulShowing === 'more' ? 'none' : 'more')}
        >
          {t.more}
        </button>
        {ulShowing === 'more' && (
          <ul>
            <li>
              <a href='https://walabot.com/pages/legal-info'>{t.legal}</a>
            </li>
            <li>
              <a
                className='hover-purple'
                href='https://walabot.com/policies/privacy-policy'
              >
                {t.privacy}
              </a>
            </li>
            <li>
              <a href='https://walabot.com/policies/refund-policy'>
                {t.refund}
              </a>
            </li>
            <li>
              <a href='https://walabot.com/pages/cookie-consent'>{t.cookie}</a>
            </li>

            <li>
              <a href='https://forms.microsoft.com/r/z8KenSD31d'>
                {t.privacy_form}
              </a>
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default MobileContent;
// export default function DIY2Footer() {
//   return (
//     <StaticQuery
//       query={graphql`
//         query {

//           en: translation(id: { eq: "english-new-diy-pages" }) {
//             ...NewDIYTranslations
//           }
//           es: translation(id: { eq: "spanish-new-diy-pages" }) {
//             ...NewDIYTranslations
//           }
//           de: translation(id: { eq: "german-new-diy-pages" }) {
//             ...NewDIYTranslations
//           }
//           fr: translation(id: { eq: "english-new-diy-pages" }) {
//             ...NewDIYTranslations
//           }
//         }
//       `}
//       render={(data) => <Footer data={data} />}
//     />
//   );
// }
