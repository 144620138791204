import React from "react"

const IconDIYDevices = () => {
  return (
    <svg
      width="104"
      height="84"
      viewBox="0 0 104 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 22.75H84C89.1086 22.75 93.25 26.8914 93.25 32V68C93.25 73.1086 89.1086 77.25 84 77.25H14C8.89137 77.25 4.75 73.1086 4.75 68V32C4.75 26.8914 8.89137 22.75 14 22.75Z"
        fill="url(#paint0_radial_1202_2756)"
      />
      <path
        d="M14 22.75H84C89.1086 22.75 93.25 26.8914 93.25 32V68C93.25 73.1086 89.1086 77.25 84 77.25H14C8.89137 77.25 4.75 73.1086 4.75 68V32C4.75 26.8914 8.89137 22.75 14 22.75Z"
        fill="url(#paint1_linear_1202_2756)"
        fillOpacity="0.3"
      />
      <path
        d="M14 22.75H84C89.1086 22.75 93.25 26.8914 93.25 32V68C93.25 73.1086 89.1086 77.25 84 77.25H14C8.89137 77.25 4.75 73.1086 4.75 68V32C4.75 26.8914 8.89137 22.75 14 22.75Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.2725 48.7236C50.5351 48.7236 51.5587 47.7099 51.5587 46.4593C51.5587 46.4014 51.5565 46.3441 51.5522 46.2874C53.0139 47.0874 54.0037 48.6296 54.0037 50.4007C54.0037 52.9945 51.8808 55.0972 49.2619 55.0972C46.6431 55.0972 44.5201 52.9945 44.5201 50.4007C44.5201 48.6207 45.5199 47.072 46.9937 46.2754C46.9887 46.336 46.9862 46.3974 46.9862 46.4593C46.9862 47.7099 48.0098 48.7236 49.2725 48.7236Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7505 40.1552C43.8946 40.1552 40.2313 43.4816 39.1478 48.0865C39.0115 48.6661 38.4265 49.0265 37.8413 48.8914C37.2561 48.7564 36.8923 48.177 37.0286 47.5974C38.3086 42.1571 42.7445 38 48.7505 38C54.6371 38 59.9452 41.8281 60.9832 47.6547C61.0876 48.2408 60.6926 48.7997 60.1008 48.9031C59.5091 49.0066 58.9448 48.6153 58.8404 48.0292C58.0099 43.3673 53.7258 40.1552 48.7505 40.1552ZM60.1391 51.9606C60.7268 52.0849 61.1013 52.6575 60.9758 53.2395C59.6379 59.441 54.6641 63 48.7505 63C42.7455 63 37.8958 58.2673 37.016 53.1969C36.9142 52.6104 37.3117 52.0532 37.9039 51.9524C38.4961 51.8515 39.0587 52.2453 39.1605 52.8318C39.8763 56.9568 43.8935 60.8448 48.7505 60.8448C53.6988 60.8448 57.737 57.9383 58.8478 52.7892C58.9734 52.2072 59.5515 51.8362 60.1391 51.9606Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <path
        d="M6 17L12 21.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M19 22H84.5"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="1 8"
      />
      <path
        d="M59 4.75H96C96.6904 4.75 97.25 5.30964 97.25 6V76C97.25 76.6904 96.6904 77.25 96 77.25H59C58.3096 77.25 57.75 76.6904 57.75 76V6C57.75 5.30964 58.3096 4.75 59 4.75Z"
        fill="url(#paint2_radial_1202_2756)"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M78 55C85.732 55 92 48.732 92 41C92 33.268 85.732 27 78 27C70.268 27 64 33.268 64 41C64 48.732 70.268 55 78 55Z"
        fill="url(#paint3_radial_1202_2756)"
        fillOpacity="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7816 39.2067C78.8999 39.2067 79.8064 38.3002 79.8064 37.182C79.8064 37.1159 79.8772 37.0711 79.9339 37.1052C81.1549 37.8397 81.9717 39.1776 81.9717 40.7063C81.9717 43.0256 80.0916 44.9057 77.7723 44.9057C75.453 44.9057 73.5729 43.0256 73.5729 40.7063C73.5729 39.1735 74.3941 37.8325 75.6206 37.0993C75.6813 37.063 75.7569 37.1113 75.7569 37.182C75.7569 38.3002 76.6634 39.2067 77.7816 39.2067Z"
        fill="#8916ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.6198 31.8138C73.3193 31.8138 70.0751 34.7881 69.1155 38.9057C68.9948 39.424 68.4767 39.7462 67.9585 39.6255C67.4402 39.5047 67.118 38.9867 67.2387 38.4684C68.3723 33.6038 72.3008 29.8867 77.6198 29.8867C82.8332 29.8867 87.5341 33.3097 88.4534 38.5196C88.5459 39.0437 88.196 39.5435 87.672 39.636C87.1479 39.7284 86.6481 39.3785 86.5556 38.8545C85.8201 34.686 82.0261 31.8138 77.6198 31.8138ZM87.7059 42.3698C88.2263 42.481 88.558 42.993 88.4468 43.5134C87.2619 49.0585 82.8571 52.2409 77.6198 52.2409C72.3017 52.2409 68.0068 48.0091 67.2275 43.4753C67.1374 42.9508 67.4895 42.4526 68.0139 42.3625C68.5384 42.2723 69.0366 42.6244 69.1268 43.1489C69.7607 46.8373 73.3184 50.3138 77.6198 50.3138C82.0022 50.3138 85.5785 47.7149 86.5623 43.1107C86.6735 42.5903 87.1855 42.2586 87.7059 42.3698Z"
        fill="#8916ED"
        fillOpacity="0.8"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1202_2756"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.99998 87.0811) rotate(-41.5758) scale(120.308 182.377)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.179218" stopColor="#14021D" />
          <stop offset="0.701069" stopColor="#292A41" />
          <stop offset="0.817754" stopColor="#3A3B4F" />
          <stop offset="0.895688" stopColor="#2B2B37" />
          <stop offset="1" stopColor="#212224" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1202_2756"
          x1="56"
          y1="47.5"
          x2="5.5"
          y2="47.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0406854" stopColor="#111111" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_1202_2756"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(57 90) rotate(-68.7626) scale(113.187 116.696)"
        >
          <stop stopColor="#71717E" />
          <stop offset="0.0001" />
          <stop offset="0.179218" stopColor="#14021D" />
          <stop offset="0.701069" stopColor="#292A41" />
          <stop offset="0.817754" stopColor="#3A3B4F" />
          <stop offset="0.895688" stopColor="#2B2B37" />
          <stop offset="1" stopColor="#212224" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_1202_2756"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(64 58.5) rotate(-50.2739) scale(46.1573 27.7912)"
        >
          <stop offset="0.0541388" stopOpacity="0" />
          <stop offset="0.640625" stopColor="#333B4A" stopOpacity="0.813049" />
          <stop offset="0.775481" stopColor="#B6BCC7" />
          <stop offset="1" stopColor="#353B48" stopOpacity="0.75" />
        </radialGradient>
      </defs>
    </svg>
  )
}

export default IconDIYDevices
