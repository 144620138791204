import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import LogoSection from './Footer/logoSection';
import SocialSection from './Footer/socialSection';
import MobileContent from '../global/Footer/mobileContent';
// import LanguageSelector from '../global/Menu/languageSelector';

const Footer = ({ locale, props }) => {
  const today = new Date();
  const year = today.getFullYear();

  const data = useStaticQuery(graphql`
    query {
      en: translation(id: { eq: "english-new-diy-pages" }) {
        ...FooterTranslations
      }
      es: translation(id: { eq: "spanish-new-diy-pages" }) {
        ...FooterTranslations
      }
      de: translation(id: { eq: "german-new-diy-pages" }) {
        ...FooterTranslations
      }
      fr: translation(id: { eq: "english-new-diy-pages" }) {
        ...FooterTranslations
      }
    }
  `);

  const t = data[locale].translation.newDIY.footer;

  return (
    <footer className={`footer-wrapper diy`} id='footer-container'>
      <div className={`container diy`}>
        <div className='content diy2'>
          <LogoSection />
          <div className='middle-section-wrapper'>
            <div className='section middle desktop'>
              <p className='title'>Collections</p>
              <ul>
                <li>
                  <a href='https://walabot.com/pages/devices'>
                    Devices Collection
                  </a>
                </li>
                <li>
                  <a href='https://walabot.com/'>Bundles Collection</a>
                </li>

                <li>
                  <a href='https://walabot.com/pages/walabot-diy-2'>DIY 2</a>
                </li>
                <li>
                  <a href='https://walabot.com/pages/walabot-diy-plus-x'>
                    DIY Plus X
                  </a>
                </li>
              </ul>
            </div>
            <div className='section middle desktop'>
              <p className='title'>{t.support}</p>
              <ul>
                <li>
                  <a
                    href='https://support.walabot.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t.contact}
                  </a>
                </li>
                <li>
                  <a href='https://walabot.com/pages/return'>{t.return}</a>
                </li>
              </ul>
            </div>
            <div className='section middle desktop'>
              <p className='title'>{t.company}</p>
              <ul>
                <li>
                  <a
                    className='hover-purple'
                    href='https://walabot.com/pages/about-us'
                  >
                    {t.about}
                  </a>
                </li>
                <li>
                  <Link to={`/docs/walabot-press-kit`}>{t.press}</Link>
                </li>
              </ul>
            </div>
            <div className='section middle desktop'>
              <p className='title'>{t.more}</p>
              <ul>
                <li>
                  <a
                    className='hover-purple'
                    href='https://walabot.com/pages/legal-info'
                  >
                    {t.legal}
                  </a>
                </li>
                <li>
                  <a
                    className='hover-purple'
                    href='https://walabot.com/policies/privacy-policy'
                  >
                    {t.privacy}
                  </a>
                </li>
                <li>
                  <a
                    className='hover-purple'
                    href='https://walabot.com/policies/refund-policy'
                  >
                    {t.refund}
                  </a>
                </li>
                <li>
                  <a
                    className='hover-purple'
                    href='https://walabot.com/pages/cookie-consent'
                  >
                    {t.cookie}
                  </a>
                </li>

                <li>
                  <a href='https://forms.microsoft.com/r/z8KenSD31d'>
                    {t.privacy_form}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <MobileContent t={t} />
          {/* <DIY2Mobile /> */}
          <div className='section social social-media-wrapper'>
            <p>{t.follow}</p>
            <SocialSection />
          </div>
        </div>
        <div className={`diy copyright-section`}>
          {/* <LanguageSelector props={props} /> */}
          <p className='copyright'>©Walabot {year} • Vayyar Imaging</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
