import React from 'react';

const IconCatalog = () => {
  return (
    <svg
      width='51'
      height='78'
      viewBox='0 0 51 78'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41.3721 7H9.62791C7.62427 7 6 8.63736 6 10.6571V67.3429C6 69.3626 7.62427 71 9.62791 71H41.3721C43.3757 71 45 69.3626 45 67.3429V10.6571C45 8.63736 43.3757 7 41.3721 7Z'
        fill='url(#paint0_linear_1202_2856)'
      />
      <path
        d='M16.8837 21.6286H13.2558C12.254 21.6286 11.4419 22.4473 11.4419 23.4571V27.1143C11.4419 28.1242 12.254 28.9429 13.2558 28.9429H16.8837C17.8855 28.9429 18.6977 28.1242 18.6977 27.1143V23.4571C18.6977 22.4473 17.8855 21.6286 16.8837 21.6286Z'
        fill='url(#paint1_radial_1202_2856)'
      />
      <path
        d='M16.8837 35.3429H13.2558C12.254 35.3429 11.4419 36.1615 11.4419 37.1714V40.8286C11.4419 41.8385 12.254 42.6571 13.2558 42.6571H16.8837C17.8855 42.6571 18.6977 41.8385 18.6977 40.8286V37.1714C18.6977 36.1615 17.8855 35.3429 16.8837 35.3429Z'
        fill='url(#paint2_radial_1202_2856)'
      />
      <path
        d='M16.8837 49.0571H13.2558C12.254 49.0571 11.4419 49.8758 11.4419 50.8857V54.5429C11.4419 55.5528 12.254 56.3714 13.2558 56.3714H16.8837C17.8855 56.3714 18.6977 55.5528 18.6977 54.5429V50.8857C18.6977 49.8758 17.8855 49.0571 16.8837 49.0571Z'
        fill='url(#paint3_radial_1202_2856)'
      />
      <path
        d='M39.6775 22.5429H23.0598C22.8311 22.5429 22.6457 22.7297 22.6457 22.9603C22.6457 23.1908 22.8311 23.3776 23.0598 23.3776H39.6775C39.9061 23.3776 40.0915 23.1908 40.0915 22.9603C40.0915 22.7297 39.9061 22.5429 39.6775 22.5429Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 24.6294H23.0598C22.8311 24.6294 22.6457 24.8163 22.6457 25.0468C22.6457 25.2774 22.8311 25.4642 23.0598 25.4642H39.6775C39.9061 25.4642 40.0915 25.2774 40.0915 25.0468C40.0915 24.8163 39.9061 24.6294 39.6775 24.6294Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 26.7169H23.0598C22.8311 26.7169 22.6457 26.9038 22.6457 27.1343C22.6457 27.3649 22.8311 27.5517 23.0598 27.5517H39.6775C39.9061 27.5517 40.0915 27.3649 40.0915 27.1343C40.0915 26.9038 39.9061 26.7169 39.6775 26.7169Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 36.2571H23.0598C22.8311 36.2571 22.6457 36.444 22.6457 36.6745C22.6457 36.9051 22.8311 37.0919 23.0598 37.0919H39.6775C39.9061 37.0919 40.0915 36.9051 40.0915 36.6745C40.0915 36.444 39.9061 36.2571 39.6775 36.2571Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 38.3437H23.0598C22.8311 38.3437 22.6457 38.5306 22.6457 38.7611C22.6457 38.9916 22.8311 39.1785 23.0598 39.1785H39.6775C39.9061 39.1785 40.0915 38.9916 40.0915 38.7611C40.0915 38.5306 39.9061 38.3437 39.6775 38.3437Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 40.4312H23.0598C22.8311 40.4312 22.6457 40.6181 22.6457 40.8486C22.6457 41.0791 22.8311 41.266 23.0598 41.266H39.6775C39.9061 41.266 40.0915 41.0791 40.0915 40.8486C40.0915 40.6181 39.9061 40.4312 39.6775 40.4312Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 49.9714H23.0598C22.8311 49.9714 22.6457 50.1583 22.6457 50.3888C22.6457 50.6193 22.8311 50.8062 23.0598 50.8062H39.6775C39.9061 50.8062 40.0915 50.6193 40.0915 50.3888C40.0915 50.1583 39.9061 49.9714 39.6775 49.9714Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 52.058H23.0598C22.8311 52.058 22.6457 52.2449 22.6457 52.4754C22.6457 52.7059 22.8311 52.8928 23.0598 52.8928H39.6775C39.9061 52.8928 40.0915 52.7059 40.0915 52.4754C40.0915 52.2449 39.9061 52.058 39.6775 52.058Z'
        fill='#8B97AD'
      />
      <path
        d='M39.6775 54.1455H23.0598C22.8311 54.1455 22.6457 54.3324 22.6457 54.5629C22.6457 54.7934 22.8311 54.9803 23.0598 54.9803H39.6775C39.9061 54.9803 40.0915 54.7934 40.0915 54.5629C40.0915 54.3324 39.9061 54.1455 39.6775 54.1455Z'
        fill='#8B97AD'
      />
      <defs>
        <linearGradient
          id='paint0_linear_1202_2856'
          x1='49.0814'
          y1='62.3143'
          x2='5.74824'
          y2='29.7349'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='0.0001' stopColor='#FDFCFC' />
          <stop offset='0.90625' stopColor='#F6F1F1' />
          <stop offset='1' stopColor='#EBEBEB' />
        </linearGradient>
        <radialGradient
          id='paint1_radial_1202_2856'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.5 64.2632) rotate(90) scale(6.73684 19.5)'
        >
          <stop stopColor='#8916ED' />
          <stop offset='1' stopColor='#7715CD' />
        </radialGradient>
        <radialGradient
          id='paint2_radial_1202_2856'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.5 64.2632) rotate(90) scale(6.73684 19.5)'
        >
          <stop stopColor='#8916ED' />
          <stop offset='1' stopColor='#7715CD' />
        </radialGradient>
        <radialGradient
          id='paint3_radial_1202_2856'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(25.5 64.2632) rotate(90) scale(6.73684 19.5)'
        >
          <stop stopColor='#8916ED' />
          <stop offset='1' stopColor='#7715CD' />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default IconCatalog;
